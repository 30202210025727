.request-form {
  padding: 0 0 60px 0;

  &__container {
    @extend .container;
    max-width: 1200px
  }

  &__form {
    font-size: 0.8em;
    background: mix($dark, $white, 90%);
    border: 1px solid $dark;
    padding: 50px;
    color: $white;
  }
}