.resources {
  padding: 80px 0;
  color: $white;

  &__container {
    @extend .container;
  }

  h2 {
    @extend .heading;
    @extend .heading--white;
    margin-bottom: 100px;
  }

  &__items {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
  }

  &__item {
    flex-grow: 1;
    text-align: center;
    padding: 0 20px 0 20px;

    img {
      height: 110px;
      width: auto;
      display: inline-block;
      margin: 0 auto;
    }

    h3 {
      text-transform: uppercase;
      padding-top: 60px;

      &, a {
        color: $white;
      }

      a:hover {
        text-decoration: none;
      }

      &:before {
        content: '';
        width: 100px;
        height: 4px;
        display: block;
        margin: 0 auto;
        background: $white;
        position: relative;
        top: -30px;
      }
    }

  }

  &--dion {
    background-color: $dion;

    h3 a:hover {
      color: mix($white, $dion, 80%);
    }
  }

  &--aldo {
    background-color: $aldo;

    h3 a:hover {
      color: mix($white, $aldo, 80%);
    }
  }

  &--enzo {
    background-color: $enzo;

    h3 a:hover {
      color: mix($white, $enzo, 80%);
    }
  }

  &--cody {
    background-color: $cody;

    h3 a:hover {
      color: mix($white, $cody, 80%);
    }
  }

  @media (max-width: 900px) {
    &__item {
      padding: 0;

      img {
        height: 120px;
      }

      h3 {
        font-size: 0.7em;
      }
    }
  }

  @media (max-width: 500px) {
    &__items {
      flex-wrap: wrap;
    }

    &__item {
      width: 100%;
      margin-bottom: 80px;
    }
  }

}