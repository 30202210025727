.heading {
  color: $green;
  text-align: center;

  &:after {
    content: '';
    display: block;
    width: 152px;
    height: 26px;
    background-image: url('../images/heading-underline.svg');
    margin: 40px auto;
  }

  &--purple, &--aldo {
    color: $purple;

    &:after {
      background-image: url('../images/heading-underline--purple.svg');
    }
  }

  &--blue, &--cody {
    color: $blue;

    &:after {
      background-image: url('../images/heading-underline--blue.svg');
    }
  }

  &--orange, &--dion {
    color: $orange;

    &:after {
      background-image: url('../images/heading-underline--orange.svg');
    }
  }

  &--red, &--enzo {
    color: $red;

    &:after {
      background-image: url('../images/heading-underline--red.svg');
    }
  }

  &--white {
    color: $white;

    &:after {
      background-image: url('../images/heading-underline--white.svg');
    }
  }
}