.split-configurations {
  background-color: $dark;
  padding: 80px 0;

  &__container {
    @extend .container;
  }

  h2 {
    @extend .heading;
    margin-bottom: 100px;
  }

  h3 {
    margin-bottom: 100px;
  }

  &__group {
    @include grid-column(6);
    text-align: center;
  }

  &__image {
    @include grid-column(4);
    border: 2px solid $white;
    @include border-radius(50%);
    overflow: hidden;
    margin: -50px auto 0 auto;
    display: inline-block;
    position: relative;
    left: 10px;

    img {
      width: 100%;
      height: auto;
      display: block;
      margin: 0;
    }
  }

  &__item {
    @include grid-column(4);
    text-align: center;
    padding: 0 20px 0 20px;

    img {
      height: 380px;
      width: auto;
      display: inline-block;
      margin: 0 auto;
      max-width: 100%;
    }

    h3 {
      text-transform: uppercase;
      padding-top: 60px;

      &:before {
        content: '';
        width: 100px;
        height: 4px;
        display: block;
        margin: 0 auto;
        position: relative;
        top: -30px;
      }
    }

    p {
      font-size: 0.9em;
      line-height: 1.1em;
      margin: 5px 0 0 0;
    }
  }

  &--dion {
    h2 {
      @extend .heading--dion;
    }

    h3 {
      color: $dion;

      &:before {
        background: $dion;
      }
    }

    .split-configurations__image {
      border-color: $dion;
    }
  }

  &--aldo {
    h2 {
      @extend .heading--aldo;
    }

    h3 {
      color: $aldo;

      &:before {
        background: $aldo;
      }
    }

    .split-configurations__image {
      border-color: $aldo;
    }
  }

  &--enzo {
    h2 {
      @extend .heading--enzo;
    }

    h3 {
      color: $enzo;

      &:before {
        background: $enzo;
      }
    }

    .split-configurations__image {
      border-color: $enzo;
    }
  }

  &--cody {
    h2 {
      @extend .heading--cody;
    }

    h3 {
      color: $cody;

      &:before {
        background: $cody;
      }
    }

    .split-configurations__image {
      border-color: $cody;
    }
  }

  @media (max-width: 1450px) {
    &__image {
      @include grid-column(3);
    }

    &__item {
      @include grid-column(4.5);
    }
  }

  @media (max-width: 1250px) {
    &__group {
      @include grid-column(12);
      margin-bottom: 80px;
    }

    &__image {
      @include grid-column(4);
    }

    &__item {
      @include grid-column(4);
    }
  }

  @media (max-width: 800px) {
    &__image {
      @include grid-column(3);
    }

    &__item {
      @include grid-column(4.5);
    }
  }

  @media (max-width: 750px) {
    &__image {
      @include grid-column(12);
      max-width: 148px;
      margin: 0 auto;
    }

    &__item {
      @include grid-column(12);
    }
  }

}