.technical-details {
  padding: 80px 0;

  &__container {
    @extend .container;
    text-align: center;
  }

  &__grouped {
    @include grid-column(12);
  }

  &__group {
    @include grid-column(6);

    @media (max-width: 800px) {
      @include grid-column(12);
    }
  }

  h2 {
    @extend .heading;
    margin-bottom: 100px;
  }

  &__subheading {
    font-weight: normal;
    text-transform: uppercase;
    position: relative;
    top: -40px;
  }

  img {
    width: 100%;
    height: auto;
    display: inline-block;
    margin: 0 auto 40px auto;
  }

  &__bullet {
    display: inline-block;
    margin-right: 5px;
    width: 20px;
    height: 20px;
    background-image: url('../images/triangle--dion.svg');
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    top: 12px;
  }

  &--dion {
    .technical-details__bullet {
      background-image: url('../images/triangle--dion.svg');
    }

    h2 {
      @extend .heading--dion;
    }

    .technical-details__subheading {
      color: $dion;
    }
  }

  &--aldo {
    .technical-details__bullet {
      background-image: url('../images/triangle--aldo.svg');
    }

    h2 {
      @extend .heading--aldo;
    }

    .technical-details__subheading {
      color: $aldo;
    }
  }

  &--enzo {
    .technical-details__bullet {
      background-image: url('../images/triangle--enzo.svg');
    }

    h2 {
      @extend .heading--enzo;
    }

    .technical-details__subheading {
      color: $enzo;
    }
  }

  &--cody {
    .technical-details__bullet {
      background-image: url('../images/triangle--cody.svg');
    }

    h2 {
      @extend .heading--cody;
    }

    .technical-details__subheading {
      color: $cody;
    }
  }
}