.text-section {
  padding: 60px 0;

  &__container {
    @extend .container;
    text-align: center;

    h2, h1 {
      @extend .heading;
      @extend .h2;
    }
  }

  &--purple {
    .heading {
      @extend .heading--purple;
    }

    h2, h1 {
      color: $purple;
    }
  }

  &--blue {
    .heading {
      @extend .heading--blue;
    }

    h2, h1 {
      color: $blue;
    }
  }

  &--orange {
    .heading {
      @extend .heading--orange;
    }

    h2, h1 {
      color: $orange;
    }
  }

  &--red {
    .heading {
      @extend .heading--red;
    }

    h2, h1 {
      color: $red;
    }
  }
}
