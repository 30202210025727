$header-grid: (
        columns: 10,
        gutter: 0,
);

$header-height: 230px;
$logo-width: 200px;

.header {
  height: $header-height;
  overflow: visible;
  background: $green;
  position: relative;
  z-index: 999;

  &__container {

  }

  &__logo {
    background: $dark;
    width: $logo-width;
    height: $header-height;
    text-align: center;
    float: left;
    position: absolute;
    z-index: 300;
    display: flex;

    img {
      object-position: center;
      object-fit: contain;
      width: 74%;
      margin: 6% 13%;
    }

    &:after {
      @include transition(all 0.25s ease-in);
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 24px 0 24px 24px;
      border-color: transparent transparent transparent darken($dark, 2%);
      position: absolute;
      top: 48px;
      right: -23px;
      z-index: 99;
      opacity: 0;
    }

    &:hover {
      background: darken($dark, 2%);

      &:after {
        opacity: 1;
      }
    }
  }

  &__mobile-nav {
    @media screen and (min-width: 900px) {
      display: none;
    }

    display: flex;
    flex-direction: column;
    width: 100%;
    background: $green;
    padding: 0 10px;
    text-align: center;

    .header__contact {
      padding: 10px 0;
    }

    a {
      color: $white;
      padding: 10px 0;
    }
  }

  &__rest {
    height: $header-height/2;
    margin-left: $logo-width;
    position: relative;
  }

  &__usps {
    width: calc(100vw - 200px) !important;
    height: $header-height/2;
    margin-left: $logo-width;
    position: absolute;
    bottom: 0;
    z-index: 200;

    @media (max-width: 1100px) {
      display: none !important;
    }

    .usp {

      @media (max-width: 1480px) {
        &-text {
          p {
            display: none;
          }
        }
      }

      @media (max-width: 1200px) {
        &-text {
          strong {
            font-size: 0.6em;
          }
        }
      }

      @media (max-width: 1000px) {
        width: calc(100% / 3);
        &:nth-child(4) {
          display: none;
        }
      }

      @media (max-width: 900px) {
        width: calc(100% / 2);
        &:nth-child(3) {
          display: none;
        }
      }
    }

  }

  &__link {
    @include grid-column(1.25, $header-grid);
    height: $header-height/2;
    display: inline-block;
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border: 20px solid $green;
    position: relative;
    border-width: 20px 30px;

    em {
      width: 48px;
      height: 24px;
      display: block;
      position: relative;
      margin: 0 auto;
      z-index: 300;

      &:after {
        position: absolute;
        left: 0;
        bottom: -95px;
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 24px 24px 0 24px;
        border-color: $green transparent transparent transparent;
        opacity: 0;
        @include transition(all 0.25s ease-in);
        z-index: 300;
      }
    }

    &:hover {
      em:after {
        opacity: 1;
      }
    }

    &--aldo {
      background-color: $purple;
      background-image: url('../images/aldo.svg');
      border-color: $purple;

      &:hover {
        background-color: darken($purple, 5%);
        border-color: darken($purple, 5%);
      }

      em:after {
        border-color: darken($purple, 5%) transparent transparent transparent;
      }
    }

    &--cody {
      background-color: $blue;
      background-image: url('../images/cody.svg');
      border-color: $blue;

      &:hover {
        background-color: darken($blue, 5%);
        border-color: darken($blue, 5%);
      }

      em:after {
        border-color: darken($blue, 5%) transparent transparent transparent;
      }
    }

    &--dion {
      background-color: $orange;
      background-image: url('../images/dion.svg');
      border-color: $orange;

      &:hover {
        background-color: darken($orange, 5%);
        border-color: darken($orange, 5%);
      }

      em:after {
        border-color: darken($orange, 5%) transparent transparent transparent;
      }
    }

    &--enzo {
      background-color: $red;
      background-image: url('../images/enzo.svg');
      border-color: $red;

      &:hover {
        background-color: darken($red, 5%);
        border-color: darken($red, 5%);
      }

      em:after {
        border-color: darken($red, 5%) transparent transparent transparent;
      }
    }
  }

  &__other {
    @include grid-column(5, $header-grid);
    text-align: right;
    padding: 28px;
    font-size: 0.75em;
    overflow: hidden;
    background: $green;
    height: calc($header-height / 2);
  }

  &__contact {
    &, a {
      color: $dark;
      text-decoration: none;
    }

    a {
      font-weight: bold;
    }
  }

  &__links {
    margin-top: 10px;

    &, a {
      color: $white;
      font-weight: bold;
      text-decoration: none;
    }

    a {
      position: relative;
      display: inline-block;

      &:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 24px 24px 24px;
        border-color: transparent transparent #1b323e transparent;
        position: absolute;
        bottom: -26px;
        left: 28%;
        @include transition(all 0.25s ease-in);
        opacity: 0;
      }

      &:hover {
        color: mix($white, $green, 85%);

        &:after {
          opacity: 1;
        }
      }
    }

  }

  &__hamburger {
    background-image: url('../images/hamburger.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 48px;
    height: 32px;
    display: none;
  }

  @media (max-width: 1300px) {
    &__link {
      @include grid-column(1.5, $header-grid);
    }

    &__other {
      @include grid-column(4, $header-grid);
      line-height: 1em;
      font-size: 0.7em;
    }

    &__links {
      a:after {
        display: none !important;
      }
    }
  }

  @media (max-width: 1100px) {
    &__link {
      width: 25%;
      margin: 0 !important;
    }

    &__other {
      width: 100%;
      margin: 0 !important;
      text-align: left;
    }
  }

  @media (max-width: 900px) {
    background: $dark;
    height: $header-height / 2;

    &__logo {
      height: $header-height / 2;

      img {
        width: auto;
        height: 92px;
        float: left;
        margin-left: 40px;
      }

      &:after {
        display: none;
      }
    }

    &__rest {
      display: none;
      position: absolute;
      top: $header-height / 2;
      left: 0;
      margin: 0;
      width: 100%;
    }

    &__link {
      width: 50%;
      border-width: 40px 100px;
    }

    &__other {
      display: none;
    }

    &__hamburger {
      display: block;
      float: right;
      cursor: pointer;
      margin-right: 40px;
      height: $header-height / 2;
      position: absolute;
      top: 0;
      right: 0;
    }

  }

  @media (max-width: 600px) {
    &__link {
      border-width: 40px 78px;
    }
  }

  @media (max-width: 500px) {
    &__link {
      border-width: 40px 60px;
    }
  }

  @media (max-width: 400px) {
    &__link {
      border-width: 40px 34px;
    }
  }

  @media (max-width: 300px) {
    &__link {
      width: 100%;
    }
  }

}
