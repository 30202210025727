h1, h2, h3, h4, h5 {
  font-family: $heading-font;
  font-weight: 900;
  margin: 0;
  line-height: 1em;

}

.h1, h1 {
  font-size: 2.75em;

}

.h2, h2 {
  font-size: 2em;

}

.h3, h3 {
  font-size: 1em;
}

.linked-text, a {
  text-decoration: none;
  color: $green;

  &:hover {
    text-decoration: underline;
  }
}

.highlight {
  font-size: 1.3em;
  line-height: 1.3em;
}
