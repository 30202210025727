.nbs-source-partner-mini {
  padding: 45px 0;
  background: $dark-purple;
  color: $white;

  &__container {
    @extend .container;
    max-width: 1500px;
  }

  &__logo {
    @include grid-column(3);
    position: relative;

    &:after {
      content: '';
      display: block;
      width: 2px;
      background: $white;
      height: 100px;
      position: absolute;
      top: -10px;
      right: 0;
    }

    img {
      width: 100%;
      height: auto;
      max-width: 278px;
      margin: 0;
    }


    @media (max-width: 1500px) {
      @include grid-column(12);

      &:after {
        display: none;
      }
    }
  }

  &__text {
    @include grid-column(9);
    position: relative;
    top: 10px;

    p {
      line-height: 1.25em;
    }

    a {
      color: $white;
      font-weight: 800;
    }

    @media (max-width: 1500px) {
      @include grid-column(12);
    }
  }


  @media (max-width: 1500px) {
    text-align: center;

    &__logo{
      margin-bottom: 30px;
    }
  }
}