.gallery {
  padding: 80px 0;

  &__container {
    @extend .container;
    position: relative;
  }

  &__slides {
    position: relative;
  }

  h2 {
    @extend .heading;
  }

  .pager {
    bottom: -40px;

    &-dot {
      border-color: mix($dark, $white);
    }
  }

  &__slide {
    height: 417px;
    width: 410px;
    position: relative;
    z-index: 88;
    padding: 25px;
  }

  &__image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%
  }

  &__control {
    display: block;
    width: 69px;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    position: absolute;
    top: 0;
    background-position: center 65%;

    &--prev {
      left: 20px;
    }

    &--next {
      right: 20px;
    }

    svg {
      @include middle();
      width: 69px;
      height: 69px;
      margin-top: 80px;
    }
  }

  &--dion {
    h2 {
      @extend .heading--dion;
    }

    svg {
      fill: $dion;
    }
  }

  &--aldo {
    h2 {
      @extend .heading--aldo;
    }

    svg {
      fill: $aldo;
    }
  }

  &--enzo {
    h2 {
      @extend .heading--enzo;
    }

    svg {
      fill: $enzo;
    }
  }

  &--cody {
    h2 {
      @extend .heading--cody;
    }

    svg {
      fill: $cody;
    }
  }

  @media (max-width: 800px) {
    &__control {
      $control-size: 40px;
      svg {
        width: $control-size;
        height: $control-size;
      }
      width: $control-size;
    }
  }

  @media (max-width: 650px) {
    &__control {
      display: none;
    }
  }
}