.map {
  background: mix($dark, $white);
  width: 100%;
  min-height: 600px;
  max-height: 1151px;
  position: relative;

  > div {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}