.door-links {
  padding-bottom: 60px;

  &__container {
    @extend .container;
  }

  h2 {
    @extend .heading;
  }

  &__door {
    @include grid-column(3);
    text-align: center;
    padding: 0 60px;

    &--dion {
      .door-links__button {
        background-color: $orange;

        &:hover {
          background-color: darken($orange, 5%);
        }
      }

      .door-links__view {
        color: $orange;
        border-color: $orange;

        &:hover {
          color: darken($orange, 10%);
          border-color: darken($orange, 10%);
        }
      }
    }

    &--aldo {
      .door-links__button {
        background-color: $purple;

        &:hover {
          background-color: darken($purple, 5%);
        }
      }

      .door-links__view {
        color: $purple;
        border-color: $purple;

        &:hover {
          color: darken($purple, 10%);
          border-color: darken($purple, 10%);
        }
      }
    }

    &--cody {
      .door-links__button {
        background-color: $blue;

        &:hover {
          background-color: darken($blue, 5%);
        }
      }

      .door-links__view {
        color: $blue;
        border-color: $blue;

        &:hover {
          color: darken($blue, 10%);
          border-color: darken($blue, 10%);
        }
      }
    }

    &--enzo {
      .door-links__button {
        background-color: $red;

        &:hover {
          background-color: darken($red, 5%);
        }
      }

      .door-links__view {
        color: $red;
        border-color: $red;

        &:hover {
          color: darken($red, 10%);
          border-color: darken($red, 10%);
        }
      }
    }
  }

  &__button {
    display: block;
    color: $white;
    font-size: 0.7em;
    padding: 6px 10px;
    border: 10px solid transparent;
    line-height: 1em;
    min-height: 45px;
    text-decoration: none;
    position: relative;
    margin: 0 0 50px 0;

    em {
      width: 100%;
      font-style: normal;
      position: absolute;
      left: 0;
      top: 50%;
      -ms-transform: translateY(-50%);
      @include transform(translateY(-50%));
    }
  }

  &__view {
    display: block;
    color: $white;
    font-size: 1em;
    padding: 10px 10px;
    line-height: 1em;
    text-decoration: none;
    font-weight: 900;
    border: 2px solid transparent;
    margin: 5px 0 50px 0;

    &:hover {
      text-decoration: none;
    }
  }

  @media (max-width: 1400px) {
    &__door {
      padding: 0 20px;
    }
  }

  @media (max-width: 1100px) {
    &__door {
      padding: 0;
    }
  }

  @media (max-width: 900px) {
    &__door {
      @include grid-column(6);
      margin-top: 40px;
      margin-bottom: 40px;
      padding: 0 40px;
    }
  }

  @media (max-width: 750px) {
    &__door {
      padding: 0 10px;
    }
  }

  @media (max-width: 650px) {
    &__door {
      @include grid-column(12);
      padding: 0 40px;
    }
  }

  @media (max-width: 450px) {
    &__door {
      padding: 0;
    }
  }
}