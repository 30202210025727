.case-studies {
  padding: 80px 0;

  &__container {
    @extend .container;

    h1 {
      color: $green;
      font-size: 1.5em;
      margin-bottom: 60px;
      @include grid-column(12);
    }
  }

  &__block {
    @include grid-column(6);
    margin-bottom: 30px;

    @media (max-width: 990px) {
      @include grid-column(12);
      margin-bottom: 60px;
    }
  }

  &__image {
    width: 100%;
    aspect-ratio: 16 / 9;
    display: flex;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      object-position: center;
      display: block;
      margin: 0;
    }
  }

  &__title {
    padding: 20px 0;
  }
}