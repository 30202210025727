@font-face {
  font-family: 'Avenir';
  src: url('../fonts/Avenir-Roman.eot');
  src: url('../fonts/Avenir-Roman.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Avenir-Roman.woff') format('woff'),
  url('../fonts/Avenir-Roman.ttf') format('truetype'),
  url('../fonts/Avenir-Roman.svg#Avenir-Roman') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  src: url('../fonts/Avenir-Medium.eot');
  src: url('../fonts/Avenir-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Avenir-Medium.woff') format('woff'),
  url('../fonts/Avenir-Medium.ttf') format('truetype'),
  url('../fonts/Avenir-Medium.svg#Avenir-Medium') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  src: url('../fonts/Avenir-Black.eot');
  src: url('../fonts/Avenir-Black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Avenir-Black.woff') format('woff'),
  url('../fonts/Avenir-Black.ttf') format('truetype'),
  url('../fonts/Avenir-Black.svg#Avenir-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

//Font name
$heading-font: 'Avenir', sans-serif;
$body-font: 'Avenir', sans-serif;

$font-size: 20px;