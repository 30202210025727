.clickable {
  cursor: pointer;
}

.ui-dialog .ui-dialog-content {
  font-size: 0.8em;
}

.preview {
  .slideshow, .slideshow__slide, .slideshow__image {
    min-height: 800px;
  }
}

section, footer {
  opacity: 0;
  @include transition(opacity 1s ease-in);

  &.visible {
    opacity: 1;
  }
}

.pager {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 111;
  text-align: center;
  padding: 10px;
  width: 100%;

  &-dot {
    display: inline-block;
    width: 15px;
    height: 15px;
    border: 2px solid $white;
    @include border-radius(50%);
    margin: 0 8px;
    cursor: pointer;
    background: transparent;

    &.cycle-pager-active {
      background: $dark;
    }

    &:hover {
      border-color: $green;
    }
  }
}

.full-width-image {

  &__picture {
    display: flex;
    min-height: 50vh;
    max-height: 100vh;

    img {
      object-fit: cover;
      margin-bottom: 0;
    }
  }
}

.news-footer {
  margin-top: 50px;

  &__container {
    @extend .container;

    a {
      color: $dark;
      font-size: 0.7em;
      display: block;
      border-bottom: 2px solid $green;
      margin-bottom: 80px;
      padding-bottom: 4px;
    }
  }
}