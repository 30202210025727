.lightboxOverlay {
  background: $black;
}

.lightbox {

  .lb-container {
    @include box-shadow(0 0 10px rgba($black, 0.25));
  }

  .lb-image {
    @include border-radius(0);
    border: 20px solid $white;
  }

  .lb-outerContainer {
    @include border-radius(0);
  }

  .lb-data {

    .lb-number{
      display: none !important;
    }

    .lb-close {
      background-size: contain;
      width: 15px;
      height: 15px;
    }
  }

  .lb-dataContainer {
    padding-top: 20px;
  }
}