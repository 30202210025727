.news-block {
  background: $white;
  margin-bottom: 50px;
  @include box-shadow(0 0 10px rgba($dark, 0.33));
  border-bottom: 15px solid $green;
  @include transition(all 0.5s ease);

  &:hover {
    @include transform(translateY(-8px));
  }

  a {
    text-decoration: none;
  }

  p {
    color: $dark;
    margin-top: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }

  &__container {
    @extend .container;
    padding: 0 50px;
  }


  &__head {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 0;
    width: 50%;
  }

  &__date, &__subject {
    margin: 0;
    font-weight: 400;
    text-transform: none;
    font-size: 0.75em;
    color: $dark;
  }

  &__subject {
    opacity: 0.5;
  }

  &__title {
    text-transform: none;
    font-weight: 400;
    color: $dark;
    width: 50%;
  }

  @media (max-width: 990px) {
    &__title {
      padding-top: 20px;
    }

    &__title, &__row {
      width: 100%;
    }
  }

  &__image {
    display: flex;
    left: -50px;
    position: relative;
    width: calc(100% + 100px);

    img {
      width: 100%;
      height: auto;
    }
  }

  &__summary, p {
    color: $dark !important;
    font-size: 0.65em;
    margin: 0;
    padding: 0 0 20px 0;
  }

}