.expanded-resources {
  padding: 80px 0;
  color: $white;

  &__container {
    @extend .container;
  }

  h2 {
    @extend .heading;
    @extend .heading--white;
    margin-bottom: 100px;
  }

  &__item {
    @include grid-column(12);
    padding: 60px 0;
    border-bottom: 4px solid $white;
    text-align: left;

    &:last-child {
      border: 0;
    }

    em {
      background-image: url('../images/download.svg');
      width: 72px;
      height: 76px;
      display: inline-block;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      float: right;
      position: relative;
      top: -35px;
    }

    img {
      height: 187px;
      width: auto;
      display: inline-block;
      margin: 0 auto;
    }

    h3 {
      text-transform: uppercase;
      padding-top: 60px;

      &, a {
        color: $white;
      }

      a:hover {
        text-decoration: none;
      }
    }

    &--group {
      ul, li {
        list-style-type: none;
        margin: 0;
        padding: 0;
      }

      a {
        color: $white;

        &:hover {
          text-decoration: none;
        }
      }

      li {
        display: block;
        margin: 40px 0;
        font-weight: 900;
        line-height: 1em;
      }

      em {
        width: 43px;
        height: 45px;
        top: -5px;
      }
    }
  }

  h2 + .expanded-resources__item {
    padding-top: 0;
  }

  &__icon {
    @include grid-column(3);
  }

  &__text {
    @include grid-column(9);
  }

  &--dion {
    background-color: $dion;

    a:hover {
      color: mix($white, $dion, 80%);
    }
  }

  &--aldo {
    background-color: $aldo;

    a:hover {
      color: mix($white, $aldo, 80%);
    }
  }

  &--enzo {
    background-color: $enzo;

    a:hover {
      color: mix($white, $enzo, 80%);
    }
  }

  &--cody {
    background-color: $cody;

    a:hover {
      color: mix($white, $cody, 80%);
    }
  }

  @media (max-width: 900px) {
    &__item {
      img {
        height: auto;
        width: 75%;
      }

      h3 {
        padding-top: 0;
      }

      em {
        display: none;
      }
    }
  }

  @media (max-width: 500px) {
    &__item {
      padding: 30px 0;
    }

    &__icon {
      display: none;
    }

    &__text {
      @include grid-column(12);
    }
  }

}