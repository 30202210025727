.mission-statement {
  &__container {
    @extend .container;
  }

  &__quote {
    background: $green;
    padding: 30px 300px;
    font-weight: 900;
    color: $white;
    border: 1px solid mix($black, $white);
    text-align: center;
  }
}