.product-features {
  &__container {
    @extend .container;
  }

  h2 {
    @extend .heading;
    margin-bottom: 100px;
  }

  &__feature {
    @include grid-column(3);
    text-align: center;
    padding: 0 20px 0 20px;
    border-right: 2px solid mix($black, $white, 10%);
    margin-bottom: 80px;

    &:nth-child(4n+5) {
      border-width: 0;
    }

    img {
      height: 150px;
      width: auto;
      display: inline-block;
      margin: 0 auto;
      max-width: 57%;
    }

    h3 {
      text-transform: uppercase;
      padding-top: 60px;

      &:before {
        content: '';
        width: 100px;
        height: 4px;
        display: block;
        margin: 0 auto;
        position: relative;
        top: -30px;
      }
    }

    p {
      font-size: 0.9em;
      line-height: 1.1em;
      margin: 5px 0 0 0;
    }
  }

  &--dion {
    h2 {
      @extend .heading--dion;
    }
    h3 {
      color: $dion;

      &:before {
        background: $dion;
      }
    }
  }

  &--aldo {
    h2 {
      @extend .heading--aldo;
    }
    h3 {
      color: $aldo;

      &:before {
        background: $aldo;
      }
    }
  }

  &--enzo {
    h2 {
      @extend .heading--enzo;
    }
    h3 {
      color: $enzo;

      &:before {
        background: $enzo;
      }
    }
  }

  &--cody {
    h2 {
      @extend .heading--cody;
    }
    h3 {
      color: $cody;

      &:before {
        background: $cody;
      }
    }
  }

  @media (max-width: 1120px) {
    &__feature {
      @include grid-column(6);
      border-right: 2px solid mix($black, $white, 10%);

      &:nth-child(2n+3) {
        border-width: 0;
      }
    }
  }

  @media (max-width: 650px) {
    &__feature {
      border: 0;
      @include grid-column(12);
    }

  }
}