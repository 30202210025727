.home-intro {
  @include background-cover;
  @include overlay($black, 0.1);
  min-height: 480px;

  &__container {
    @extend .container;
    z-index: 99;
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    @include transform(translateY(-50%));
  }

  &__text {
    color: $white;
    text-shadow: 4px 4px 17px rgba($black, 0.66);
    position: relative;
    max-width: 765px;
    display: inline-block;

    h1 {
      padding-bottom: 40px;
    }

    a {
      @extend .section-button;
    }
  }

  @media (max-width: 400px) {

    h1 {
      font-size: 2em;
    }
  }
}