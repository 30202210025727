.news-page {
  background: $white;

  &__container {
    @extend .container;
    padding-bottom: 100px;
    max-width: 1200px;
  }

  &__head {
    margin-top: 50px;
    margin-bottom: 30px
  }

  &__title {
    color: $green;
    font-size: 1.5em;
    margin-bottom: 30px;
  }

  &__image {
    max-height: 700px;
  }

  &__row {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    padding: 5px 0;

    @media screen and (min-width: 630px) {
      flex-direction: row;
    }

    p {
      margin: 0;
      color: $dark;
      margin-right: 10px;
    }
  }

  &__subjects {
    cursor: pointer;
    color: $dark;

    &.active{
      color: $black;
    }

    @media screen and (min-width: 630px) {
      margin-left: 5px;
      font-size: 0.75em;
      padding: 5px 15px;
    }

  }

}