.usps {
  background: $dark;
  width: 100%;
  height: $header-height/2;
  display: flex;
  align-items: center;
}

.usp {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;

  &-icon {
    width: 60px;
    height: 60px;
    margin: 0 20px 0 0;
    display: flex;
    align-items: center;

    img {
      margin: 0;
      width: 100%;
      max-height: 50px;
    }
  }

  &-text {
    position: relative;
    top: -2px;
    margin-right: 20px;

    strong {
      color: $green;
      font-weight: 900;
      font-size: 0.78em;
    }

    p {
      color: $white;
      text-transform: lowercase;
      font-size: 0.6em;
      margin: 0;
    }
  }


}
