.dialog {
  border: 0 !important;
  background: $white;
  color: $black !important;
  padding: 20px;
  z-index: 10001 !important;

  &__titlebar {
    display: none;
  }

  &__text {
    font-family: $heading-font;
    font-size: 1.25em;
    padding: 0 8px !important;
  }

  &__buttons {
    border: 0;
    margin-top: 0 !important;
    padding: 0;
    background: transparent;

    &-inner {
      float: none !important;
    }

    button {
      font-weight: 600 !important;
      font-family: $heading-font !important;
      font-size: 1em !important;
      padding: 10px 20px !important;
      border: 0 !important;
      margin: 0 10px 0 0 !important;
      cursor: pointer !important;
      background: $green !important;
      color: $white !important;
      position: relative !important;
      @include border-radius(1px);
      @include box-shadow(0, 1);

      &:hover {
        background: darken($green, 5%) !important;
      }
    }
  }
}