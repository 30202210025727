/* apply a natural box layout model to all elements, but allowing components to change */
html {
  @include box-sizing();
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: $white;
  color: $black;

  @media print {
    background: none;
  }
}

*, *:before, *:after {
  @include box-sizing(inherit);
}

body {
  line-height: 1.5em;
  font-size: $font-size;
  font-family: $body-font;
  overflow-x: hidden;

  @media print {
    font-size: $font-size * 0.9;
    overflow-x: visible !important;
  }
}

section {
  padding: 0;
  @extend %clearfix;
  position: relative;
  z-index: 1;

  &:first-of-type {
    /*margin-top: 100px; //Adjust first section for header

    @media (max-width: 600px) {
      margin-top: 75px;
    }

    @media print {
      margin-top: 0;
    }*/
  }

  .preview &:first-of-type {
    margin-top: 0 !important;
  }
}

.template-preview {
  transform: scale(0.77);
  transform-origin: top center;
}

img {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

input, select, textarea {
  display: inline-block;
  font-size: 1em;
  &:focus {
    outline: none;
  }
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;

  &::-ms-expand {
    display: none;
  }
}

address {
  font-style: normal;
  margin-bottom: 20px;
}

button, img, input, textarea, select, a {
  outline: none !important;
}

@include selection($green, $white);

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 98px;
  max-width: 1830px;
  @include grid-container();
  @include box-sizing();

  @media (max-width: 600px) {
    padding: 0 40px;
  }

  @media (max-width: 400px) {
    padding: 0 20px;
  }

  @media (max-width: 300px) {
    padding: 0 10px;
  }
}

select, label {
  cursor: pointer;
}

p {
  margin: 0 auto 20px auto;
  line-height: 1.8em;
}