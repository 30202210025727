$footer-grid: (
        gutter: 40px,
);

.footer {
  background: $dark;
  padding: 0 0 0 0;

  &__container {
    @extend .container;
    padding-bottom: 60px;
  }

  &__usps {
    width: calc(100% - 40px) !important;
    margin-left: 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid mix($dark, $white, 90%);
    height: auto !important;
    padding: 30px 0;
    flex-wrap: wrap;

    @media (max-width: 1400px) {
      justify-content: left;

      .usp {
        width: 50%;
        margin-bottom: 30px;
        margin-top: 30px;
      }
    }

    @media (max-width: 900px) {
      .usp {
        width: 100%;
        margin-bottom: 45px;
        margin-top: 45px;
      }
    }

  }

  &__heading {
    @include grid-column(12);
    overflow: visible;
    height: 206px;
    background-image: url('../images/footer-logo.svg');
    background-position: top right;
    background-repeat: no-repeat;
    background-size: 192px auto;
    margin-bottom: 40px;

    h2 {
      color: $green;
      border-bottom: 2px solid $green;
      padding-bottom: 8px;
      position: relative;
      top: 84px;
      font-weight: 200;
      font-size: 0.85em;
      text-transform: uppercase;

      strong {
        font-weight: 900;
      }
    }
  }

  &__columns {
    @include grid-collapse();
  }

  &__column {
    @include grid-column(4, $footer-grid);
    color: $white;
    font-size: 0.6em;

    label {
      display: block;
    }

    &--nav {
      h3 {
        color: $green;
        font-size: 1.25em;
        margin-bottom: 20px;
      }

      .footer__nav {
        display: flex;
        flex-wrap: wrap;

        &-link {
          width: 50%;
          height: 2em;

          a {
            color: $white;
          }
        }
      }
    }

    &--interested {
      p {
        font-weight: 900;
        margin-bottom: 5px;
      }

      input {
        margin-top: 20px;
      }
    }

    &--form {

      input, textarea {
        @include grid-column(12);
      }

      textarea {
        margin-bottom: 20px;
      }

      p {
        @include grid-column(7);
        font-size: 0.9em;
      }

      button {
        @include grid-column(5);
        margin-top: 2px;
      }
    }

    &--contact {
      text-align: right;

      h3 {
        font-size: 1.45em;
        font-weight: normal;
        margin: 0 0 10px 0;

        a {
          color: $white;
        }
      }

      p {
        margin: 50px 0 0 0;
        color: darken($white, 40%);
      }
    }
  }


  &__group {
    background: mix($black, $dark);
    padding: 15px;
    text-align: center;

    img {
      height: 15px;
      width: auto;
      display: inline-block;
      margin: 0;
    }
  }

  @media (max-width: 1550px) {
    &__column--form {
      p, button {
        @include grid-column(12);
      }
    }
  }

  @media (max-width: 1250px) {
    &__heading {
      background-image: none;
      height: auto;
      margin-bottom: 80px;
    }

    &__column {
      @include grid-column(12, $footer-grid);
      margin-bottom: 40px;

      &--nav {
        margin-top: 80px;
      }

      &--form {
        * {
          width: 100% !important;
          margin-left: 0 !important;
        }
      }

      &--contact {
        text-align: center;
      }
    }
  }
}

.social-media {
  position: fixed;
  top: 50%;
  right: 0;
  background-color: rgba($white, 0.48);
  z-index: 9999;
  padding: 5px 10px;
  @include border-radius(10px 0 0 10px);

  &__link {
    display: block;
    width: 40px;
    height: 40px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 5px 0;
    @include border-radius(10px);
    border: 10px solid transparent;

    span {
      display: none;
    }

    &--linkedin {
      background-color: $linkedin;
      border-color: $linkedin;
      background-image: url('../images/linkedin.svg');

      &:hover {
        border-color: darken($linkedin, 5%);
        background-color: darken($linkedin, 5%);
      }
    }

    &--youtube {
      background-color: $youtube; //
      border-color: $youtube;
      background-image: url('../images/youtube.svg');

      &:hover {
        border-color: darken($youtube, 5%);
        background-color: darken($youtube, 5%);
      }
    }
  }
}