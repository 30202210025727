$footer-grid: (
        gutter: 40px,
);

.contact-footer {
  background: $dark;
  padding: 60px 0 0 0;

  + .footer .footer__container {
    display: none;
  }

  &__container {
    @extend .container;
    padding-bottom: 60px;
  }

  h3 {
    color: $green;
    margin-bottom: 40px;
  }

  &__speak {
    @include grid-column(4, $footer-grid);

    a {
      display: block;
      color: $white;
    }

    p {
      font-size: 0.6em;
      color: $white;
      margin-top: 40px;

      strong {
        display: block;
        font-weight: 900;
      }
    }
  }

  &__form {
    @include grid-column(7, $footer-grid);
    @include grid-push(1);

    h3 {
      @include grid-column(12);
    }

    input, textarea, p, button {
      font-size: 0.6em;
    }

    textarea {
      margin-bottom: 20px;
      margin-top: 6px;
      height: 123px;
      resize: none;
    }

    p {
      @include grid-column(9);
      color: darken($white, 40%);
    }

    button {
      @include grid-column(3);
      margin-top: 2px;
    }
  }

  &__inputs, &__textarea {
    @include grid-column(6);
    margin-bottom: 20px;
  }
}