.wysiwyg-editor {
  padding: 0;
  margin: 0;

  section {
    padding: 80px 0;
    margin: 0;
  }

  section, footer {
    opacity: 1 !important;
  }

  .home-portfolio {
    &__item {
      height: 666px;
    }

    &__background, &__info {
      height: 333px;
    }

    &__info {
      opacity: 1;
      bottom: 0;
    }
  }

  .service-squares {
    &__item {
      height: 666px;
    }

    &__background, &__info {
      height: 333px;
    }

    &__info {
      opacity: 1;
      bottom: 0;
    }
  }

  .about-quotes {
    min-height: 1000px !important;
  }

  .about-team {
    &__member {
      &-more {
        padding: 10px;
        height: auto;
      }

      &-overlay {
        opacity: 1;
        height: 50px !important;
        position: relative;
      }
    }
  }

  .js-repeatable-item {
    &:hover {
      cursor: crosshair;
      background-color: rgba($green, 0.66);
      border: 1px solid $green;
      @include box-shadow(inset 0 0 10px $green);
    }
  }

  .js-bg-image, img {
    &:hover {
      cursor: crosshair;
      background-color: rgba($green, 0.66);
      border: 1px solid $green;
      @include box-shadow(inset 0 0 10px $green);
    }
  }

}