.checkbox {
  @extend %clearfix;
  display: inline-block;
  margin-right: 4px;
  vertical-align: top;

  &__input {
    width: 32px;
    display: none;

    + .checkbox__fake {
      display: inline-block;
      width: 22px;
      height: 22px;
      background: $white;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      top: 7px;
      margin-right: 15px;
      margin-bottom: 5px;
      float: left;

      &:after {
        content: '';
        display: block;
        width: 15px;
        height: 15px;
        border: 1px solid $white;
        background-image: url('../images/check.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        left: 3px;
        top: -32px;
        @include transition(all 0.5s ease);
      }
    }

    &:checked + .checkbox__fake {
      border: 1px solid $green;

      &:after {
        top: 3px;
      }
    }
  }

  &__text {
    font-size: 0.75em;
    display: block;
    position: relative;
    top: -7px;
    margin-bottom: 15px;
  }

}