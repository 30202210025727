.slideshow {
  position: relative;

  &__slide {
    width: 100%;
    position: relative;
    z-index: 88;
  }

  &__image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  &__slides {
    position: relative;
  }

  &__box {
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    @include transform(translateY(-50%));
    left: 0;
    z-index: 222;
    padding: 58px 97px;

    a {
      @extend .section-button;
    }

    img {
      max-width: 350px;
      width: 100%;
      height: auto;
      display: inline-block;
      margin-bottom: 32px;
    }
  }

  &--aldo {
    border-bottom: 4px solid $purple;

    .slideshow__box {
      background-color: rgba($purple, 0.8);
    }
  }

  &--cody {
    border-bottom: 4px solid $blue;

    .slideshow__box {
      background-color: rgba($blue, 0.8);
    }
  }

  &--dion {
    border-bottom: 4px solid $orange;

    .slideshow__box {
      background-color: rgba($orange, 0.8);
    }
  }

  &--enzo {
    border-bottom: 4px solid $red;

    .slideshow__box {
      background-color: rgba($red, 0.8);

      @media (max-width: 750px) {
        top: 80px;
        @include transform(none);
      }
    }

    .slideshow__logo{
      background-image: url('../images/ezyjamb.svg');
      @include background-contain();
      background-position: right;
      position: absolute;
      bottom: 80px;
      right: 100px;
      display: block;
      width: 256px;
      height: 100px;
      z-index: 9999;

      @media (max-width: 750px) {
        height: 50px;
        right: 50px;
      }

      @media (max-height: 830px) {
        bottom: 20px;
        right: 20px;
      }

      @media (max-height: 790px) {
        display: none;
      }
    }
  }

  @media(max-width: 750px) {
    &__box {
      width: 100%;
    }
  }

  @media(max-width: 500px) {
    &__box {
      padding: 55px;
    }
  }

  @media(max-width: 400px) {
    &__box {
      padding: 55px 20px;

      a {
        text-align: center;
        width: 100%;

        &:before {
          display: none;
        }
      }
    }
  }
}