input, select, textarea {
  font-weight: normal;
  font-family: $body-font;
  text-decoration: none;
  padding: 8px 13px 9px;
  width: 100%;
  background: transparent;
  color: $white;
  border: 1px solid $white;
  @include transition(all 0.5s ease);
  @include border-radius(1px);
  margin: 0 0 5px 0;
  line-height: 1em;
  @include selection($green, $green);

  /*TODO: clear placeholder on focus, keep focus style if filled*/

}

textarea {
  line-height: 1.5em;
  resize: none;
}