.contact-with-map {
  background: $dark;
  @include grid-collapse();
  color: $white;
  display: flex;
  flex-direction: row;
  padding-top: 50px;
  padding-bottom: 50px;

  &__text {
    display: flex;
    flex-direction: column;
  }

  &__contact {
    @include grid-column(5, $gutterless-grid);
    text-align: right;
    //max-height: 858px;
    //height: calc(100vh - 50px);
    background: $dark;
    padding: 0 50px;
    height: 100%;

    h2, h1 {
      color: $green;
      margin: 0 0 20px 0 !important;
      @extend .h2;
    }

    p {
      margin: 20px 0 !important;
    }

    .button {

    }

    a {
      color: $green;
    }

    input, textarea, select {
      width: 100%;
      @include box-sizing();
    }
  }

  &__contact-inner {
    //margin: 72px 0 0 0;
    width: 100%;
    max-width: 485px;
    display: inline-block;
    text-align: left;
    padding: 0 30px;
    @include box-sizing();
  }

  &__map {
    @include grid-column(7, $gutterless-grid);
    //max-height: 858px;
    //height: calc(100vh - 50px);
    height: 100%;
    color: $dark;
  }

  &__map-inside {
    max-height: 858px;
    height: calc(100vh - 50px);
  }

  @media (max-width: 1150px) {
    flex-direction: column;

    &__contact {
      @include grid-column(12, $gutterless-grid);
      text-align: center;
      padding: 0;


    }

    &__contact-inner {
      margin-top: 0;
    }

    &__map {
      @include grid-column(12, $gutterless-grid);
    }
  }
}