button, .button {
  display: inline-block;
  background: $green;
  color: $white;
  font-weight: 900;
  text-decoration: none;
  padding: 10px 15px 8px 20px;
  cursor: pointer;
  font-family: $heading-font;
  border: 0;
  font-size: 1.1em;

  &:hover {
    background: darken($green, 5%);
  }
}

.section-button{
  position: relative;
  display: inline-block;
  font-weight: 900;
  border: 2px solid $white;
  text-decoration: none;
  color: $white;
  padding: 5px 20px;
  margin-top: 40px;
  font-size: 1.3em;

  &:before {
    content: '';
    display: block;
    width: 286px;
    height: 2px;
    background: $white;
    position: absolute;
    top: -48px;
    left: 0;
  }
}