.nbs-source-partner {
  padding: 60px 0;
  background: $dark-purple;
  color: $white;
  text-align: center;

  &__container {
    @extend .container;
    max-width: 1400px;

    a {
      color: $white;
      font-weight: 800;
    }
  }

  &__logo {
    width: 100%;
    height: auto;
    max-width: 278px;
    margin: 0 auto 30px auto;
  }
}