@media print {

  h1 {
    margin-bottom: 10px;
  }

  h2, h3 {
    margin-bottom: 10px;
    margin-top: 20px;
  }

}